import { ISharedServiceState } from "@/lib/types";
import helpers from "@/utils/helpers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

/**
 * {
    "adherent": [
        {
            "civility": "man",
            "dob": "1992-08-05",
            "year": "1992",
            "type": "main",
            "franchise": "1500",
            "couvertureAccident": "oui",
            "pack": {
                "id": 1,
                "principal": "Essentials",
                "options": [
                    {
                        "label": "Hospitalisation",
                        "level": 2,
                        "id": 4
                    },
                    {
                        "label": "Médecine alternative",
                        "level": 1,
                        "id": 1
                    },
                    {
                        "label": "Traitements dentaires",
                        "level": 3,
                        "id": 2
                    },
                    {
                        "label": "Capital hospitalier",
                        "level": 3,
                        "id": 3
                    }
                ]
            }
        },
        {
            "civility": "female",
            "dob": "1996-07-09",
            "year": "1996",
            "type": "partner",
            "franchise": "2000",
            "couvertureAccident": "oui",
            "pack": {
                "id": 1,
                "principal": "Essentials",
                "options": [
                    {
                        "id": 4,
                        "label": "Hospitalisation",
                        "level": 1
                    }
                ]
            }
        },
        {
            "dob": "2008-08-05",
            "year": "2008",
            "type": "child",
            "franchise": "500",
            "pack": {
                "id": 1,
                "principal": "Essentials",
                "options": [
                    {
                        "id": 1,
                        "label": "Médecine alternative",
                        "level": 1
                    }
                ]
            }
        }
    ],
    "selectedAdherent": [],
    "email": "",
    "for": "you, your partner and your kids",
    "nom": "",
    "npa": {
        "key": -1,
        "value": "Not on the territory"
    },
    "phone": "",
    "prenom": "",
    "situation": "future resident",
    "verified": false,
    "frontalierInfo": {},
    "economieimpots": true
}
 */

interface Adherent {
  civility?: string;
  dob: string;
  year: string;
  type: string;
  franchise?: string;
  couvertureAccident?: string;
  pack?: Pack;
  travailSuisse?: boolean;
  couverture?: boolean;
}

export interface Pack {
  id: number;
  principal: string;
  options: Option[];
}

interface Option {
  id: number;
  label: string;
  level: number;
}

interface Npa {
  key: number;
  value: string;
}

interface FrontalierInfo {}

type PayloadPossibleTypes =
  | string
  | number
  | boolean
  | Adherent
  | Pack
  | Option
  | Npa
  | any[];

export interface IAssuranceSanteInitialState extends ISharedServiceState {
  data: {
    adherent: Adherent[];
    selectedAdherent: any[];
    email: string;
    for?: string;
    nom: string;
    npa?: string;
    phone: string;
    prenom: string;
    situation?: string;
    verified: boolean;
    frontalierInfo?: FrontalierInfo;
    economieimpots?: boolean;
    hasInsurance?: boolean;
    actualAssurance?: string;
  };
}

const initialState: IAssuranceSanteInitialState = {
  versionId: "",
  _start: "",
  data: {
    adherent: [],
    selectedAdherent: [],
    email: "",
    nom: "",
    phone: "",
    prenom: "",
    verified: false,
    frontalierInfo: {},
  },
  stepWithError: [],
  currentStep: {
    id: "for-who",
    group: "adherent",
    branch: "standard",
  },
  currentVisibleStep: {
    id: "for-who",
    group: "adherent",
    branch: "standard",
  },
  loaded: false,
  errors: {},
};

const assuranceSanteSlice = createSlice({
  name: "assuranceSante",
  initialState,
  reducers: {
    reset: () => initialState,

    initWizard: (state) => {
      state.versionId = helpers.generateUUIDv4();
      state._start = new Date().toISOString();
      state.loaded = true;
    },

    updateData: (
      state,
      action: PayloadAction<{
        data: {
          item: keyof IAssuranceSanteInitialState["data"];
          value: PayloadPossibleTypes;
        };
        currentStep?: {
          id: string;
          group?: string;
          newTab?: boolean;
          branch?: string;
        };
        currentVisibleStep?: {
          id: string;
          group?: string;
          newTab?: boolean;
          branch?: string;
        };
      }>
    ) => {
      const { item, value } = action.payload.data;

      const updatedData = {
        ...state.data,
        [item]: value as IAssuranceSanteInitialState["data"][typeof item],
      };

      state.data = updatedData;

      if (
        action.payload.currentStep &&
        state.currentStep.id !== "info" &&
        state.currentStep.group !== "my_contact"
      ) {
        state.currentStep.id = action.payload.currentStep.id;

        if (action.payload.currentStep.group)
          state.currentStep.group = action.payload.currentStep.group;

        if (action.payload.currentStep.branch)
          state.currentStep.branch = action.payload.currentStep.branch;

        if (action.payload.currentStep.newTab)
          state.currentStep.newTab = action.payload.currentStep.newTab;
        else delete state.currentStep.newTab;
      }

      if (action.payload.currentVisibleStep) {
        state.currentVisibleStep.id = action.payload.currentVisibleStep.id;

        if (action.payload.currentVisibleStep.group)
          state.currentVisibleStep.group =
            action.payload.currentVisibleStep.group;

        if (action.payload.currentVisibleStep.branch)
          state.currentVisibleStep.branch =
            action.payload.currentVisibleStep.branch;

        if (action.payload.currentVisibleStep.newTab)
          state.currentVisibleStep.newTab =
            action.payload.currentVisibleStep.newTab;
        else delete state.currentVisibleStep.newTab;
      }
    },

    updateCurrentVisibleStep: (
      state,
      action: PayloadAction<{
        id: string;
        group?: string;
        branch?: string;
        newTab?: boolean;
      }>
    ) => {
      if (state.stepWithError.length > 0)
        toast.error("Please correct the errors before proceeding.");
      else
        state.currentVisibleStep = {
          ...state.currentVisibleStep,
          ...action.payload,
        };
    },

    updateCurrentStep: (
      state,
      action: PayloadAction<{
        id: string;
        group?: string;
        branch?: string;
        newTab?: boolean;
      }>
    ) => {
      if (state.stepWithError.length > 0)
        toast.error("Please correct the errors before proceeding.");
      else
        state.currentStep = {
          ...state.currentStep,
          ...action.payload,
        };
    },

    addStepError: (state, action: PayloadAction<string>) => {
      if (state.stepWithError.indexOf(action.payload) === -1)
        state.stepWithError.push(action.payload);
    },

    removeStepError: (state, action: PayloadAction<string>) => {
      state.stepWithError = state.stepWithError.filter(
        (item) => item !== action.payload
      );
    },

    checkIfErrorIsResolved: (state, action: PayloadAction<string>) => {
      if (
        Object.hasOwn(state.data, action.payload) &&
        state.data[
          action.payload as keyof IAssuranceSanteInitialState["data"]
        ] !== "" &&
        state.stepWithError.indexOf(action.payload) !== -1
      ) {
        state.stepWithError = state.stepWithError.filter(
          (item) => item !== action.payload
        );
      }
    },
  },
});

export const {
  reset,
  initWizard,
  updateData,
  updateCurrentVisibleStep,
  updateCurrentStep,
  addStepError,
  removeStepError,
  checkIfErrorIsResolved,
} = assuranceSanteSlice.actions;

export default assuranceSanteSlice.reducer;
