import { ISharedServiceState } from "@/lib/types";
import helpers from "@/utils/helpers";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
export interface CompleteAddress {
  postalCode: string;
  state: string;
  city: string;
  country: string;
}
interface IAssuranceMenageState extends ISharedServiceState {
  data: {
    nom: string;
    prenom: string;
    phone: string;
    email: string;
    dob: string;
    nationalite: string;
    name: string;
    firstname: string;
    verified: boolean;
    insuranceType?: string;
    owner?: boolean;
    nb_people?: number;
    logement_size?: number;
    logement_type?: string;
    address?: string;
    complete_address?: CompleteAddress;
    logement_amenagement?: string;
  };
}

type PayloadPossibleTypes = string | boolean | number | CompleteAddress;

const initialState: IAssuranceMenageState = {
  versionId: "",
  _start: "",
  data: {
    nom: "",
    prenom: "",
    phone: "",
    email: "",
    dob: "",
    nationalite: "",
    name: "",
    firstname: "",
    verified: false,
  },
  stepWithError: [],
  currentStep: {
    id: "insurance_type",
    group: "project",
    branch: "standard",
  },
  currentVisibleStep: {
    id: "insurance_type",
    group: "project",
    branch: "standard",
  },
  loaded: true,
  errors: {},
};

const assuranceMenageSlice = createSlice({
  name: "assuranceMenage",
  initialState,
  reducers: {
    reset: () => initialState,

    initWizard: (state) => {
      state.versionId = helpers.generateUUIDv4();
      state._start = new Date().toISOString();
      state.loaded = true;
    },

    updateData: (
      state,
      action: PayloadAction<{
        data: {
          item: keyof IAssuranceMenageState["data"];
          value: PayloadPossibleTypes;
        };
        currentStep?: {
          id: string;
          group?: string;
          newTab?: boolean;
          branch?: string;
        };
        currentVisibleStep?: {
          id: string;
          group?: string;
          newTab?: boolean;
          branch?: string;
        };
      }>
    ) => {
      const { item, value } = action.payload.data;

      const updatedData = {
        ...state.data,
        [item]: value as IAssuranceMenageState["data"][typeof item],
      };

      state.data = updatedData;

      if (
        action.payload.currentStep &&
        state.currentStep.id !== "info" &&
        state.currentStep.group !== "my_contact"
      ) {
        state.currentStep.id = action.payload.currentStep.id;

        if (action.payload.currentStep.group)
          state.currentStep.group = action.payload.currentStep.group;

        if (action.payload.currentStep.branch)
          state.currentStep.branch = action.payload.currentStep.branch;

        if (action.payload.currentStep.newTab)
          state.currentStep.newTab = action.payload.currentStep.newTab;
        else delete state.currentStep.newTab;
      }

      if (action.payload.currentVisibleStep) {
        state.currentVisibleStep.id = action.payload.currentVisibleStep.id;

        if (action.payload.currentVisibleStep.group)
          state.currentVisibleStep.group =
            action.payload.currentVisibleStep.group;

        if (action.payload.currentVisibleStep.branch)
          state.currentVisibleStep.branch =
            action.payload.currentVisibleStep.branch;

        if (action.payload.currentVisibleStep.newTab)
          state.currentVisibleStep.newTab =
            action.payload.currentVisibleStep.newTab;
        else delete state.currentVisibleStep.newTab;
      }
    },

    updateCurrentVisibleStep: (
      state,
      action: PayloadAction<{
        id: string;
        group?: string;
        branch?: string;
        newTab?: boolean;
      }>
    ) => {
      if (state.stepWithError.length > 0)
        toast.error("Please correct the errors before proceeding.");
      else
        state.currentVisibleStep = {
          ...state.currentVisibleStep,
          ...action.payload,
        };
    },

    addStepError: (state, action: PayloadAction<string>) => {
      if (state.stepWithError.indexOf(action.payload) === -1)
        state.stepWithError.push(action.payload);
    },

    removeStepError: (state, action: PayloadAction<string>) => {
      state.stepWithError = state.stepWithError.filter(
        (item) => item !== action.payload
      );
    },

    checkIfErrorIsResolved: (state, action: PayloadAction<string>) => {
      if (
        Object.hasOwn(state.data, action.payload) &&
        state.data[action.payload as keyof IAssuranceMenageState["data"]] !==
          "" &&
        state.stepWithError.indexOf(action.payload) !== -1
      ) {
        state.stepWithError = state.stepWithError.filter(
          (item) => item !== action.payload
        );
      }
    },
  },
});

export const {
  reset,
  initWizard,
  updateData,
  updateCurrentVisibleStep,
  addStepError,
  removeStepError,
  checkIfErrorIsResolved,
} = assuranceMenageSlice.actions;

export default assuranceMenageSlice.reducer;
